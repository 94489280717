<template>
  <div>
    <!-- 点评内容页面 -->
    <div class="comment_page">
      <div class="main_title">点评内容</div>
      <van-divider />
      <div class="comment_title">*病例内容完整性</div>
      <div class="group_box">
        <van-radio-group v-model="radio">
          <van-radio name="1" class="radio_text" icon-size="14px">很好</van-radio>
          <van-radio name="2" class="radio_text" icon-size="14px">一般</van-radio>
          <van-radio name="3" class="radio_text" icon-size="14px">较差</van-radio>
        </van-radio-group>
      </div>
      
      <van-divider />
      <div class="comment_title">*病例内容典型性</div>
      <div class="group_box">
        <van-radio-group v-model="radio">
          <van-radio name="1" class="radio_text" icon-size="14px">很好</van-radio>
          <van-radio name="2" class="radio_text" icon-size="14px">一般</van-radio>
          <van-radio name="3" class="radio_text" icon-size="14px">较差</van-radio>
        </van-radio-group>
      </div>
      <van-divider />
      
      <div class="group_box">
        <div class="other_text">其他</div>
        <textarea class="other_ipt" name="" id="" cols="34" rows="3">
        </textarea>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="btn">
        <van-button round type="info" size="large" style="height:43px" class="submit_btn" @click="Submit()">提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      radio: ""
    }
  },
  methods: {
    // 点击提交按钮
    Submit(){ 
      console.log("提交")
    }
  }
}
</script>

<style lang="scss" scoped>
.comment_page{
  padding:25px 15px 0;
  .main_title{
    display: flex;
  }
  .group_box{
    margin-left: 18px;
    // display: flex;
    .radio_text{
      font-size: 16px;
      margin: 7px;
    }
    .other_ipt{
      display: flex;
      border: 1px solid #ccc;
    }
    .other_text{
      display: flex;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  .comment_title{
    display: flex;
    font-size: 16px;
    color: #101010;
  }
  .btn{
    background: #ccc;
    .van-button__text{
      font-size: 20px;
      color: #fff;
    }
    .submit_btn{
      width: 90%;
      height: 43px;
    }
  }
}
</style>